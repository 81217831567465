.SocialAddRequestList {
  overflow-x: auto;
  margin-top: 1rem;
}

.SocialAddRequestList__Header {
  display: flex;
  align-items: center;
  column-gap: 14px;
}

.SocialAddRequestList__Header > * {
  flex-shrink: 0;
}

.SocialAddRequestList__Header > button {
  width: fit-content;
}

.SocialAddRequestList__Title {
  font-size: var(--font-large);
}

.SocialAddRequestList__ItemBox + .SocialAddRequestList__ItemBox {
  margin-top: 6px;
}

.SocialAddRequestList__ItemBox {
  display: flex;
  align-items: center;
  column-gap: 6px;
  cursor: default;
}

.SocialAddRequestList__Item--Title {
  font-weight: 700;
}

.SocialAddRequestList__Item {
  width: 140px;
  flex-shrink: 0;
}

.SocialAddRequestList__Item--Actions {
  display: flex;
  column-gap: 10px;
}

.SocialAddRequestList__Item--Add-Btn {
  margin-top: 0.4rem;
}
